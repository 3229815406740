[data-v-302486ff] .el-dialog__body {
  text-align: left !important;
}
[data-v-302486ff] .el-dialog__header {
  text-align: left;
  border-bottom: solid 1px #dcdfe6;
}
[data-v-302486ff] .el-tab-pane {
  padding-right: 15px;
}
[data-v-302486ff] .el-tabs__content {
  padding-left: 10px;
}
[data-v-302486ff] .el-collapse-item__header {
  background-color: #f1f6fc;
  padding-left: 8px;
}
[data-v-302486ff] .el-collapse-item__content {
  padding: 20px 0 10px;
}
[data-v-302486ff] .el-collapse {
  border-bottom: none;
}
.trigger[data-v-302486ff] .el-collapse-item__wrap {
  border-bottom: none;
}
